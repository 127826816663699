export const formItem = [
	{
		type: 'input',
		label:'品牌名称',
		prop:'brandName',
		placeholder:'请输入'
	},
]
export const columns = [
	{
		title: "品牌名称",
		dataIndex: "brandName",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}