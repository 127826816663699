import httpService from "@/request"

// 商品
// 品牌
export function brandList(params) {
	return httpService({
		url: `/user/shopBrand/list`,
		method: 'get',
		params: params,
	})
}
export function brandInsert(params) {
	return httpService({
		url: `/user/shopBrand/insert`,
		method: 'post',
		data: params,
	})
}
export function brandDel(params) {
	return httpService({
		url: `/user/shopBrand/delete`,
		method: 'post',
		data: params,
	})
}
export function brandUpdate(params) {
	return httpService({
		url: `/user/shopBrand/update`,
		method: 'post',
		data: params,
	})
}
